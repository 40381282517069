import React from "react";
import ReactDOM from "react-dom";
import WebFont from "webfontloader";
import App from "./App";
import "./assets/vars.css";
import "./index.less";
import dotenv from "dotenv";

dotenv.config();

WebFont.load({
  google: {
    // families: ["Roboto", "Roboto:wght@500"],
    families: ["Roboto:100,300,500,700"],
  },
});

ReactDOM.render(<App />, document.getElementById("root"));
